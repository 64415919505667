/* Nunito Sans */
 @import url(https://fonts.bunny.net/css?family=nunito:500,600,800);

/* Encode Sans */
@import url(https://fonts.bunny.net/css?family=encode-sans:400,500,600,700);

/* Montserrat */
@import url(https://fonts.bunny.net/css?family=montserrat:400,500,600,700,800,900);

/* Roboto */
@import url(https://fonts.bunny.net/css?family=roboto:400,500,700,900);

/* Inter */
@import url(https://fonts.bunny.net/css?family=inter:400,500,600,700,800,900);

/* <link
      href="https://fonts.googleapis.com/css2?family=Encode+Sans:wght@300;400;500;600;700&display=swap"
      rel="stylesheet"
    /> */
/* Font-Awesome */

@import "font-awesome/css/font-awesome.min.css";

/* TippyJS */
@import "tippy.js/dist/tippy.css";
@import "tippy.js/themes/light.css";

/* Select2 */
@import "select2/dist/css/select2.min.css";

/* Toastr2 */
@import "toastr2/dist/toastr.min.css";

/* Perfect Scrollbar */
@import "perfect-scrollbar/css/perfect-scrollbar.css";

@import "codemirror/lib/codemirror.css";
@import "codemirror-spell-checker/src/css/spell-checker.css";

/* GLightbox */
@import "glightbox/dist/css/glightbox.css";

/* FlatPickr */
@import "flatpickr/dist/flatpickr.min.css";

/* Cropper */
@import "cropperjs/dist/cropper.min.css";

/* AirDatepicker */
@import "air-datepicker/air-datepicker.css";

/* Swiper */
@import "swiper/swiper-bundle.min.css";

/* Glightbox */
@import "glightbox/dist/css/glightbox.min.css";

/* Splide */
@import "@splidejs/splide/dist/css/splide.min.css";

/* IntlTelInput */
@import "intl-tel-input/build/css/intlTelInput.css";
